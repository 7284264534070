.attendance__general {
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
}

.attendance__register {
  position: relative;
  z-index: auto;
}

.attendance__student_name {
  width: 150px;
  border: 1px solid #171717ca;
}

.attendance__date {
  border: 1px solid #171717ca;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  min-width: 30px;
  padding: 8px;
  font-size: 20px;
  border: 1px solid #cccccc;
}

th.empty-cell {
  background-color: #f0f0f0;
}

td.name-cell {
  font-weight: bold;
  background-color: black;
}

.attendance__cell_general {
  width: 32px;
  height: 47px;
}

.attendance__cell_inactive {
  padding: 5px;
  background-color: grey;
}

.attendance__cell_attended {
  background-color: #57aa30;
  cursor: pointer;
}

.attendance__cell_spravka {
  background-color: #ef9e24;
  cursor: pointer;
}

.attendance__cell_unattended {
  background-color: #fa5332;
  cursor: pointer;
}

.attendance__dropdown {
  position: absolute; /* or position: fixed; */
  z-index: 100000;
  flex-direction: column;
  background-color: white;
  border: none;
}

.attendance__item {
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 2px;
  border: 1px solid black;
  cursor: pointer;
}

.attendance__item:hover {
  background-color: lightgrey;
}

.attendance__square {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.attendance__save_button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  color: #ffffff;
  font-size: 16px;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.attendance__save_button_disabled {
  background-color: gray;
  cursor: auto;
}

.attendance__save_button:hover:enabled {
  background-color: rgb(216 216 121);
  cursor: pointer;
}

@media (width <= 870px) {
  .attendance__student_name {
    width: 90px;
    font-size: 15px;
  }

  .attendance__date {
    font-size: 15px;
  }

  th,
  td {
    min-width: unset;
  }
}

@media (width <= 670px) {
  .attendance__register {
    width: 500px;
  }

  .attendance__date {
    font-size: unset;
  }
}

@media (width <= 520px) {
  .attendance__register {
    width: 300px;
  }
}

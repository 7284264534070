/* stylelint-disable selector-class-pattern */

.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  background-color: #f5f5f5;
}

.error-page__container {
  padding: 20px;
  text-align: center;
}

.error-page__title {
  margin: 0;
  color: #ff6b6b;
  font-size: 8rem;
  animation: shake 1s ease-in-out infinite alternate;
}

.error-page__message {
  margin: 20px 0;
  color: #333333;
  font-size: 1.5rem;
}

.error-page__button {
  padding: 10px 20px;
  color: white;
  font-size: 1rem;
  background-color: #0b0707;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.error-page__button:hover {
  color: black;
  background-color: #ebb40e;
}

.error-page__animation {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 40px 0;
}

.error-page__gear {
  position: relative;
  width: 50px;
  height: 50px;
  background-color: #ff6b6b;
  border-radius: 50%;
  animation: rotate 3s linear infinite;
}

.error-page__gear--one {
  animation-duration: 3s;
}

.error-page__gear--two {
  animation-duration: 2s;
}

.error-page__gear--three {
  animation-duration: 1.5s;
}

.error-page__gear::before,
.error-page__gear::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent 45%,
    rgb(0 0 0 / 10%) 50%,
    transparent 55%
  );
  transform: rotate(45deg);
}

/* Анимации */

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes shake {
  0% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(10px);
  }
}

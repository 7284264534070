.modal {
  min-width: 1000px;
  min-height: 500px;
  max-height: 80vh;
  padding: 20px;
  overflow-y: auto;
  font-size: 12px;
  background-color: white;
  border-radius: 40px;
}

.modal > .header {
  width: 100%;
  padding: 5px;
  font-size: 18px;
  text-align: center;
  border-bottom: 1px solid gray;
}

.modal > .content {
  width: 100%;
  padding: 10px 5px;
}

.modal > .actions {
  width: 100%;
  margin: auto;
  padding: 10px 5px;
  text-align: center;
}

.modal > .close {
  position: absolute;
  top: -40px;
  right: -10px;
  display: block;
  padding: 0 5px 5px;
  font-size: 34px;
  line-height: 30px;
  background: #ffffff;
  border: 1px solid #cfcece;
  border-radius: 18px;
  cursor: pointer;
}

.attendancepopup__group_title {
  width: 200px;
  margin: auto;
  margin-top: 0;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 25px;
  text-align: center;
  background-color: rgb(237 206 112);
  border-radius: 200px;
}

.attendancepopup__month_controls {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 700px;
  height: 40px;
}

.attendancepopup__month_arrows {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.attendancepopup__month_controls_leftarrow {
  min-width: 48px;
  padding-top: 40px;
  padding-left: 80px;
  background: url('../../../../assets/icons/left_arrow.png') no-repeat center
    center;
  cursor: pointer;
  opacity: 0.8;
}

.attendancepopup__month_controls_rightarrow {
  min-width: 48px;
  padding-top: 40px;
  padding-right: 80px;
  background: url('../../../../assets/icons/right_arrow.png') no-repeat center
    center;
  cursor: pointer;
  opacity: 0.8;
}

.attendancepopup__month_controls_leftarrow:hover {
  opacity: 1;
}

.attendancepopup__month_controls_rightarrow:hover {
  opacity: 1;
}

.attendancepopup__month_controls_monthtitle {
  align-self: center;
  color: rgb(74 134 219);
  font-size: 34px;
  text-shadow: 0 0 2px #ffffff;
}

.attendancepopup__no_data {
  margin-top: 30px;
  font-size: 20px;
  text-align: center;
}

@media (width <= 1070px) {
  .modal {
    min-width: 700px;
    min-height: 500px;
  }
}

@media (width <= 995px) {
  .manageattendance__month_controls {
    width: 500px;
  }
}

@media (width <= 870px) {
  .modal {
    max-width: 500px;
    height: auto;
  }
}

@media (width <= 781px) {
  .manageattendance__month_controls {
    width: 300px;
  }
}

@media (width <= 760px) {
  .modal {
    min-width: 600px;
    min-height: 300px;
  }
}

@media (width <= 730px) {
  .attendancepopup__group_title {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 25px;
  }

  .attendancepopup__month_controls_monthtitle {
    font-size: 25px;
  }

  th,
  td {
    font-size: 15px;
  }
}

@media (width <= 760px) {
  .modal > .close {
    right: 0;
  }
}

@media (width <= 670px) {
  .modal {
    min-width: unset;
    max-width: 600px;
    height: auto;
    min-height: unset;
    padding: 0;
  }

  .modal > .content {
    width: unset;
  }

  th,
  td {
    min-width: 0;
    font-size: 10px;
  }

  .attendancepopup__group_title {
    margin-top: 20px;
    font-size: 17px;
  }

  .attendancepopup__month_controls_monthtitle {
    font-size: 25px;
  }
}

@media (width <= 630px) {
  .modal {
    max-width: 500px;
  }
}

@media (width <= 585px) {
  .modal {
    max-width: 400px;
  }

  table {
    width: 75%;
  }
}

/* @media (width <= 520px) {
  .modal {
    max-width: 400px;
  }
} */

@media (width <= 400px) {
  .attendancepopup__group_title {
    padding-top: 0;
  }

  .modal {
    width: auto;
    height: auto;
    padding: 0;
  }

  table {
    width: 85%;
  }
}

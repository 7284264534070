.slick-dots {
  li button::before {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-top: 20px;
    background-color: #f3ebeb;
    border: 0.6px solid #2b2a2a;
    border-radius: 50%;
  }

  li button::before,
  li.slick-active button::before {
    color: transparent;
    opacity: 1;
  }

  li.slick-active button::before {
    background-color: #e9b209;
  }
}

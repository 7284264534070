.footer__general {
  min-height: 400px;
  color: #5b3718;
  font-size: 100%;
  background-image: url('../../../../public/intro_brick.jpg');
}

.footer__info {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-right: 5%;
  margin-left: 5%;
  padding-top: 50px;
  padding-bottom: 20px;
}

.footer__icon {
  width: 30px;
  height: 26px;
  cursor: pointer;
}

.footer__icon:hover {
  transform: scale(1.5);
  transition: all 0.2s ease-in-out 0s;
}

.footer__privacypolicy_link {
  color: #5b3718;
}

.footer__official {
  padding: 0;
  list-style-type: none;
}

.footer__working_time {
  padding: 0;
  list-style-type: none;
}

.footer__icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
}

.footer__second_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-right: 5%;
  margin-left: 5%;
  padding-bottom: 50px;
  text-align: justify;
}

.footer__admins_icon {
  display: flex;
}

.footer__perm_icon {
  display: flex;
  width: 60px;
  height: 100px;
}

.footer__rcf_icon {
  width: 60px;
  height: 100px;
}

.footer__warnings {
  max-width: 600px;
}

.footer__visa_icon {
  width: auto;
  height: 100px;
}

@media (width <= 1170px) {
  .footer__warnings {
    max-width: 400px;
  }
}

@media (width <= 795px) {
  .footer__second_section {
    margin-right: 2%;
    margin-left: 2%;
  }
}

@media (width <= 730px) {
  .footer__warnings {
    max-width: 300px;
  }
}

@media (width <= 655px) {
  .footer__second_section {
    display: grid;
    grid-template-columns: 1fr;
  }

  .footer__warnings {
    grid-column: 1 / span 2;
    order: 0;
    max-width: 700px;
    padding-bottom: 50px;
  }

  .footer__icons {
    order: 1;
  }

  .footer__visa_icon {
    order: 1;
  }
}

@media (width <= 655px) {
  .footer__info {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgb(255 255 255 / 70%);
  backdrop-filter: blur(5px);
}

.loader__spinner {
  width: 80px;
  height: 80px;
  border: 6px solid rgb(0 0 0 / 10%);
  border-top: 6px solid #3498db;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

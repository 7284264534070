.info__popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 50%);
}

.info__popup_content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}

.info__popup_title {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
}

.info__popup_text {
  margin-bottom: 15px;
  font-size: 16px;
}

.info__popup_close {
  padding: 10px 20px;
  color: white;
  background-color: #ecb515;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto;
}

.manageattendance__general {
  flex-grow: 1;
  background: url('../../../../assets/personal_account_images/attendance.jpg')
    no-repeat center center;
  background-size: cover;
}

.manageattendance__container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 200px;
  height: 100vh;
  overflow: hidden;
  resize: horizontal;
}

.manageattendance__title {
  align-self: center;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 20px;
  color: #543215;
  font-weight: bold;
  font-size: 30px;
  background: rgb(255 255 255 / 70%);
  border-radius: 40px;
}

.manageattendance__group_list {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  overflow-y: auto;
}

.manageattendance__group_item {
  position: relative;
  width: 200px;
  padding: 10px;
  color: #543215;
  text-align: center;
  background: rgb(255 255 255 / 70%);
  border: none;
  border-bottom: 2px solid #cccccc;
  border-radius: 40px;
  cursor: pointer;
}

.manageattendance__vertical_line {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 5px;
  padding: 0;
  background-color: rgb(237 206 112);
  border: none;
  cursor: ew-resize;
}

.manageattendance__details {
  flex-grow: 1;
  padding: 16px;
}

.manageattendance__group_title {
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 24px;
}

.manageattendance__group_item_selected {
  color: white;
}

.manageattendance__group_info {
  display: flex;
  min-width: 600px;
  min-height: 300px;
  margin-top: 40px;
  margin-right: 80px;
  margin-left: 80px;
  background: rgb(255 255 255 / 25%);
  border-radius: 30px;
}

.manageattendance__preload_img {
  z-index: 99;
  width: 150px;
  height: 90px;
  margin: auto;
  background: url('../../../../assets/icons/book_icon.png') no-repeat center;
}

.manageattendance__group_item:hover {
  background: rgb(237 206 112);
}

.manageattendance__general_blur {
  background: gray;
  opacity: 0.3;
}

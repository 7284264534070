.allstudentdata__popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 50%);
}

.allstudentdata__general {
  display: flex;
  flex-direction: row;
  background-color: white;
}

.allstudentdata__popup_payments {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
}

.allstudentdata__payments_title {
  margin-bottom: 10px;
  color: #333333;
  font-weight: 600;
  font-size: 30px;
}

.allstudentdata__payments_list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.allstudentdata__recommended_sum {
  margin-top: 50px;
  color: #2b6962;
  font-size: 20px;
}

.allstudentdata__payments_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.allstudentdata__payments_item:hover {
  background-color: #f1f1f1;
}

.allstudentdata__payments_amount {
  color: #2a9d8f;
  font-weight: bold;
  font-size: 16px;
}

.allstudentdata__payments_date {
  color: #777777;
  font-size: 14px;
}

.allstudentdata__popup_student_name {
  margin: auto;
  color: rgb(186 113 23);
  font-size: 30px;
}

.allstudentdata__popup_calendar {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}

.allstudentdata__popup_title {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
}

.allstudentdata__popup_text {
  margin-bottom: 15px;
  font-size: 16px;
}

.allstudentdata__popup_close {
  margin: 0 auto;
  padding: 10px 20px;
  color: white;
  background-color: #ecb515;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media (width <= 756px) {
  .allstudentdata__payments_title {
    font-size: 20px;
  }
}

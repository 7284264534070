p {
  margin: 0;
  padding: 8px;
}

.intro__general {
  margin: 0;
  color: #5b3718;
  font-weight: bold;
  font-family: 'Arial Narrow', sans-serif;
  line-height: 2rem;
  background-image: url('../../../../public/intro_brick.jpg');
}

.intro__info {
  display: grid;
  grid-template-columns: 1fr 460px 1fr;
  grid-auto-rows: min-content;
  gap: 1rem;
  padding-top: 50px;
  padding-bottom: 50px;
}

.intro__info_general {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 350px;
}

.intro__logo {
  width: 600px;
  height: 270px;
  margin-left: auto;
  background-image: url('../../../../public/logo_small_2.png');
  background-repeat: no-repeat;
  border: 0;
}

.intro__info_official {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 100px;
  color: #5b3718;
  font-family: 'Arial Narrow', sans-serif;
  text-align: right;
  cursor: pointer;
}

.intro__info_news {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.intro__programs_list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0;
  margin-bottom: auto;
  font-size: 1.3rem;
}

.intro__titles_mixin {
  padding-left: 40px;
  font-size: 1.3rem;
}

.intro__info_programs {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  max-width: 460px;
  padding-left: 100px;
}

.intro__list_news {
  margin: 0;
  margin-bottom: auto;
  font-size: 1.3rem;
}

.intro__icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 330px;
  margin: 0;
}

.intro__icon {
  width: 30px;
  height: 26px;
  cursor: pointer;
}

.intro__icon:hover {
  transform: scale(1.5);
  transition: all 0.2s ease-in-out 0s;
}

.intro__text_yellow_mixin {
  color: #e98114;
  font-weight: bold;
  list-style-type: none;
}

.intro__text_bottom_mixin {
  margin-bottom: auto;
}

@media (width <= 1640px) {
  .intro__logo {
    width: 500px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}

@media (width <= 1450px) {
  .intro__logo {
    width: 400px;
  }
}

@media (width <= 1315px) {
  .intro__info {
    grid-template-columns: 1fr 360px 1fr;
  }

  .intro__info_programs {
    padding-left: 40px;
  }

  .intro__icons {
    padding: 0;
  }
}

@media (width <= 1015px) {
  .intro__info {
    grid-template-columns: 1fr 1fr;
    padding-top: 20px;
    padding-bottom: 60px;
  }

  .intro__info_programs {
    padding-top: 70px;
  }

  .intro__info_news_mixin {
    grid-column: 1 / span 2;
    justify-self: center;
    padding-top: 30px;
  }

  .intro__icons {
    max-width: unset;
    padding-top: 15px;
  }
}

@media (width <= 740px) {
  .intro__info {
    grid-template-columns: 1fr;
  }

  .intro__info_news_mixin {
    grid-column: unset;
  }

  .intro__info_programs {
    justify-self: center;
    padding-top: 20px;
  }

  .intro__info_general {
    justify-self: center;
  }

  .intro__info_official {
    align-items: center;
    padding-top: 20px;
  }
}

.teacherpage__general {
  display: flex;
  flex-direction: row;
}

.teacherpage__maincontent {
  display: flex;
  flex-direction: column;
  width: 100%;
}

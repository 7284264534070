p {
  margin: 0;
}

.news_card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100vh;
  max-height: 750px;
  padding-right: 3%;
  padding-left: 3%;
  color: #5b3718;
  border-left: 1px solid #f1f3f4;
}

.newscard__image {
  width: 100%;
  max-height: 630px;
  object-fit: cover;
  object-position: top;
  background: no-repeat center center fixed;
}

.newscard__background {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background: #f3ebeb;
}

.newscard__title {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 20px;
  font-family: 'Items', system-ui;
  text-align: center;
}

.newscard__dot {
  position: relative;
  width: 14px;
  height: 14px;
  margin-top: 15px;
  margin-bottom: 10px;
  margin-left: 50%;
  background: #e98112;
  border-radius: 50%;
}

.newscard__text {
  padding-top: 20px;
  text-align: justify;
}

.newscard__date {
  margin-top: auto;
  color: #e98112;
}

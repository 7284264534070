.paymentpopup {
  min-width: 400px;
  max-width: 600px;
  min-height: 200px;
  max-height: 80vh;
  padding: 20px;
  overflow-y: auto;
  font-size: 12px;
  background-color: white;
  background-color: #fff3df;

  /* background-color: aliceblue; */
  border-radius: 40px;
  opacity: 0.9;
}

.paymentpopup > .header {
  width: 100%;
  padding: 5px;
  font-size: 18px;
  text-align: center;
  border-bottom: 1px solid gray;
}

.paymentpopup > .content {
  width: 100%;
  padding: 10px 5px;
}

.paymentpopup > .actions {
  width: 100%;
  margin: auto;
  padding: 10px 5px;
  text-align: center;
}

.paymentpopup > .close {
  position: absolute;
  top: -40px;
  right: -10px;
  display: block;
  padding: 0 5px 5px;
  color: white;
  font-size: 34px;
  line-height: 30px;
  background-color: transparent;

  /* background: #eaa45b; */

  /* border: 1px solid #eaa45b; */
  border: none;
  border-radius: 18px;
  cursor: pointer;
}

.paymentpopup__gen {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.paymentpopup__general {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  font-size: 25px;
}

.paymentpopup__info {
  padding-top: 50px;
  padding-bottom: 10px;
  color: #b87956;
  font-weight: bold;
  font-size: 30px;
}

.paymentpopup__input {
  padding: 10px;
  text-align: center;
  border: none;
  border-radius: 10px;
}

.paymentpopup__payment_sum {
  padding-top: 10px;
}

.paymentpopup__recommended {
  color: #644a3f;
}

.paymentpopup__confirm_button {
  min-width: 190px;
  min-height: 50px;
  margin-top: 20px;
  padding: 10px;
  color: #742f11;
  font-size: 20px;
  background-color: #eb9f56;
  border: none;

  /* color: #ffffff; */
  border-radius: 10px;
  cursor: pointer;
}

.paymentpopup__confirm_button:hover {
  background-color: #b4e61d9b;
}

.paymentpopup__input:focus {
  color: #b87956;
  outline: none;
}

.paymentpopup__icon {
  width: 130px;
  height: 130px;
}

@media (width <= 480px) {
  .paymentpopup > .close {
    right: 5px;
  }

  .paymentpopup__info {
    margin-bottom: 0;
    padding-top: 90px;
    padding-bottom: 0;
  }

  .paymentpopup {
    max-width: 400px;
    max-height: 400px;
  }
}

@media (width <= 445px) {
  .paymentpopup {
    width: 300px;
    height: 300px;
    padding: 0;
  }

  .paymentpopup__gen {
    gap: 5px;
  }

  .paymentpopup__general {
    gap: 3px;
    font-size: 20px;
  }
}

@media (width <= 400px) {
  .paymentpopup {
    min-width: unset;

    /* width: 200px; */

    /* height: 200px; */
  }

  .paymentpopup__gen {
    flex-direction: column;
    padding-top: 30px;
  }

  .paymentpopup__info {
    display: none;
    padding-top: 10px;
    font-size: 25px;
  }

  .paymentpopup__icon {
    width: 95px;
    height: 95px;
  }
}

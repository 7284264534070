.studentpage__general {
  display: flex;
  flex-direction: row;
}

.studentpage__content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

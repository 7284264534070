.wearetrustedby__general {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
}

.wearetrustedby__title {
  align-self: center;
  color: #5b3718;
  text-align: center;
}

.wearetrustedby__logos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  place-items: center center;
  align-self: center;
  gap: 20px;
  margin: 30px 5% 20px;
}

.wearetrustedby__logo_icon {
  max-width: 240px;
  max-height: 70px;
  transition: transform 0.2s ease-in-out;
}

.wearetrustedby__logo_icon:hover {
  cursor: pointer;
  transform: scale(1.1);
}

@media (width <= 860px) {
  .wearetrustedby__logos {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (width <= 650px) {
  .wearetrustedby__logos {
    grid-template-columns: 1fr 1fr;
  }
}

@media (width <= 460px) {
  .wearetrustedby__logo_icon {
    height: 50px;
  }
}

@media (width <= 400px) {
  .wearetrustedby__logos {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .wearetrustedby__logo_icon {
    max-width: 320px;
    max-height: 80px;
  }
}

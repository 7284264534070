.admissionform__wrapper {
  display: flex;
  padding: 100px;
  background:
    linear-gradient(rgb(237 230 230 / 50%), rgb(237 230 230 / 80%)),
    url('../../../../public/chess_pieces.jpeg') repeat;
}

.admissionform__general {
  width: 70%;
  margin: auto;
  font-family: sans-serif;
  border: 3px dotted #5a3617;
  border-radius: 5px;
}

.admissionform__title {
  padding: 70px;
  color: #5b3718;
  text-align: center;
}

.admissionform__block {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 15px;
}

.admissionform__info {
  margin: 0 auto 30px;
}

.admissionform__label {
  display: flex;
  align-items: center;
  width: 50%;
  margin-bottom: 5px;
  color: #5b3718;
  font-weight: bold;
}

.admissionform__input {
  box-sizing: border-box;
  min-width: 190px;
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 3px;
}

.admissionform__input_text {
  min-height: 150px;
  text-align: left;
  text-align: justify;
  vertical-align: text-top;
}

.admissionform__button {
  display: block;
  width: 300px;
  margin: auto;
  margin-bottom: 90px;
  padding: 12px;
  color: #5b3718;
  font-size: 1.5rem;
  text-align: center;
  background: #f1bc1d;
  border: 3px solid #5a3617;
  cursor: pointer;
}

.admissionform__button:hover {
  background-color: yellowgreen;
}

.admissionform__result {
  display: flex;
}

.admissionform__feedback {
  align-items: center;
  align-self: center;
  margin: auto;
  margin-top: -70px;
  text-align: center;
  border-radius: 5px;
}

@media (width <= 1250px) {
  .admissionform__general {
    width: 100%;
  }
}

@media (width <= 780px) {
  .admissionform__block {
    flex-direction: column;
    align-items: center;
  }

  .admissionform__label {
    align-self: center;
    width: unset;
    text-align: center;
  }

  .admissionform__input {
    min-width: 500px;
  }

  .admissionform__button {
    width: 200px;
  }
}

@media (width <= 780px) {
  .admissionform__input {
    min-width: 300px;
  }
}

@media (width <= 520px) {
  .admissionform__button {
    width: 200px;
    margin-bottom: 40px;
  }

  .admissionform__input {
    min-width: 200px;
  }

  .admissionform__title {
    padding: 20px;
  }
}

@media (width <= 440px) {
  .admissionform__input {
    min-width: 190px;
  }

  .admissionform__title {
    padding: 10px;
  }

  .admissionform__button {
    width: 170px;
  }
}

@media (width <= 400px) {
  .admissionform__wrapper {
    padding: 20px;
  }
}

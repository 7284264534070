.studentpayment__general {
  flex-grow: 1;
  padding-left: 300px;
  background: url('../../../../assets/personal_account_images/personal_data.jpg')
    no-repeat center center;
  background-size: cover;
}

.studentpayment__buttons {
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-top: 100px;
  margin-left: 10px;
}

.studentpayment__button {
  width: 220px;
  height: 220px;
  padding: 20px;
  text-align: center;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.studentpayment__item {
  display: flex;
  flex-direction: column;
  align-items: center;

  /* gap: 10px; */
}

.studentpayment__icon {
  width: 130px;
  height: 130px;
  transition: scale 0.4s ease-out 1ms;
}

.studentpayment__button_text {
  font-size: 20px;
}

.studentpayment__button:hover {
  background-color: rgb(227 227 48 / 80.9%);
}

.studentpayment__icon:hover {
  scale: 1.3;
}

@media (width <= 1090px) {
  .studentpayment__buttons {
    flex-direction: column;

    /* align-items:self-end; */
    align-items: flex-start;
    margin-top: 80px;
  }
}

@media (width <= 730px) {
  .studentpayment__general {
    padding-left: 0;
  }

  .studentpayment__buttons {
    align-items: center;
    gap: 0;
    margin-top: 20px;
  }
}

@media (width <= 560px) {
  .studentpayment__buttons {
    align-items: center;
  }

  .studentpayment__general {
    padding-left: 0;
  }
}

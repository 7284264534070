p {
  margin: 0;
}

.paymentsecurityinfo__buttom_icon {
  width: 150px;
  height: auto;
  margin-top: 40px;
  padding-bottom: 20px;
}

.paymentsecurityinfo__title {
  margin-bottom: 20px;
  font-size: 20px;
  text-align: center;
}

.paymentsecurityinfo__general {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}

.paymentsecurityinfo__popup {
  /* min-width: 300px; */
  max-width: 400px;
  min-height: 200px;
  max-height: 80vh;
  padding: 20px;
  overflow-y: auto;
  font-size: 12px;
  background-color: white;
  background-color: aliceblue;

  /* border: 3px solid black; */
  border-radius: 40px;
  opacity: 0.95;
}

.paymentsecurityinfo__popup > .header {
  width: 100%;
  padding: 5px;
  font-size: 18px;
  text-align: center;
  border-bottom: 1px solid gray;
}

.paymentsecurityinfo__popup > .content {
  width: 100%;
  padding: 10px 5px;
}

.paymentsecurityinfo__popup > .actions {
  width: 100%;
  margin: auto;
  padding: 10px 5px;
  text-align: center;
}

.paymentsecurityinfo__popup > .close {
  position: absolute;
  top: -40px;
  right: -10px;
  display: block;
  padding: 0 5px 5px;
  font-size: 34px;
  line-height: 30px;
  background: #ffffff;
  border: 1px solid #cfcece;
  border-radius: 18px;
  cursor: pointer;
}

.paymentsecurityinfo__text {
  font-size: 18px;
}

@media (width <= 465px) {
  .paymentsecurityinfo__popup > .close {
    right: 5px;
  }

  .paymentsecurityinfo__title {
    font-size: 15px;
  }

  .paymentsecurityinfo__text {
    font-size: 15px;
  }

  .paymentsecurityinfo__buttom_icon {
    width: 120px;
    margin-top: 20px;
  }
}

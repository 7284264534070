.employeessalaries__general {
  flex-grow: 1;
  background: url('../../../../assets/personal_account_images/homework.jpg')
    no-repeat center center;
  background-size: cover;
}

.employeessalaries__button {
  display: block;
  width: 200px;
  height: 80px;
  margin-top: 90px;
  margin-right: auto;
  margin-left: auto;
  background-color: rgb(163 232 150);
  cursor: pointer;
}

.employeessalaries__button:hover {
  background-color: rgb(66 208 37);
}

/* stylelint-disable selector-class-pattern */

.studentattendance__general {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding-left: 300px;
  background: url('../../../../assets/personal_account_images/attendance.jpg')
    no-repeat center center;
  background-size: cover;
  border: none;
}

.studentattendance__title {
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 170px;
  padding-left: 80px;
  color: rgb(95 6 6);
  font-size: 20px;
}

.react-calendar__viewContainer {
  cursor: pointer;
  pointer-events: none;
}

.react-calendar__navigation__label {
  color: rgb(103 14 14);
  font-size: 30px;
}

.react-calendar__navigation__arrow {
  font-size: 30px;
}

.react-calendar__navigation__arrow:hover {
  color: red;
}

.studentattendance__calendar {
  z-index: 1;
  width: 600px;
  margin-left: 50px;
  padding: 90px;
  font-size: 30px;
  border-radius: 10px;
  opacity: 0.64;
}

.react-calendar__tile--now {
  background: transparent;
  border: 2px solid #04aa6d;
}

.react-calendar__tile {
  color: black;
}

.react-calendar__month-view__days__day--weekend {
  color: red;
}

.studying_day {
  background-color: #b4bca8;
}

.attended {
  background-color: #80c360;
  border: 3px;
}

.spravka {
  background-color: #ef9e24;
}

.unattended {
  background-color: #fa5332;
}

.react-calendar__navigation {
  font-size: 20px;
}

.react-calendar {
  border: none;
}

.studentattendance__status-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  width: 600px;
  margin-top: 20px;
  margin-left: 50px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 30px;
  background: white;
  border-radius: 10px;
  opacity: 0.74;
}

.studentattendance__status-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;
  padding-left: 25px;
}

.studentattendance__circle-label {
  color: rgb(103 14 14);
  font-size: 20px;
}

.studentattendance__circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.studentattendance__circle:hover {
  transform: scale(1.5);
  transition: all 0.8s ease-in-out 0s;
}

@media (width <= 990px) {
  .studentattendance__general {
    padding-left: 100px;
  }
}

@media (width <= 760px) {
  .studentattendance__calendar {
    width: 400px;
    padding: 20px;
    font-size: 20px;
  }

  .studentattendance__title {
    margin-left: 70px;
  }

  .react-calendar__navigation {
    font-size: 10px;
  }

  .react-calendar__navigation__arrow {
    font-size: 20px;
  }

  .react-calendar__navigation__label {
    font-size: 20px;
  }

  .studentattendance__circle-label {
    font-size: 20px;
  }

  .studentattendance__circle {
    width: 30px;
    height: 30px;
  }

  .studentattendance__status-container {
    grid-template-columns: 1fr;
    width: 400px;
  }
}

@media (width <= 650px) {
  .studentattendance__general {
    align-items: center;
    padding-left: 0;
  }

  .studentattendance__title {
    margin-left: 0;
    padding: 0;
  }
}

@media (width <= 430px) {
  .studentattendance__title {
    padding: 0;
    text-align: center;
  }

  .studentattendance__calendar {
    margin: 0;
    padding: 0;
  }

  .studentattendance__status-container {
    margin-left: 0;
  }
}

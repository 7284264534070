.security__popup {
  /* min-width: 300px; */
  max-width: 400px;
  min-height: 200px;
  max-height: 80vh;
  padding: 20px;
  overflow-y: auto;
  font-size: 12px;
  background: conic-gradient(
      from 90deg at 1px 1px,
      #00000000 90deg,
      rgb(0 0 255 / 24.2%) 0
    )
    0 0/50px 50px;
  background-color: white;
  background-color: aliceblue;

  /* border: 3px solid black; */
  border-radius: 40px;
  opacity: 0.8;
}

.security__popup > .header {
  width: 100%;
  padding: 5px;
  font-size: 18px;
  text-align: center;
  border-bottom: 1px solid gray;
}

.security__popup > .content {
  width: 100%;
  padding: 10px 5px;
}

.security__popup > .actions {
  width: 100%;
  margin: auto;
  padding: 10px 5px;
  text-align: center;
}

.security__popup > .close {
  position: absolute;
  top: -40px;
  right: -10px;
  display: block;
  padding: 0 5px 5px;
  font-size: 34px;
  line-height: 30px;
  background: #ffffff;
  border: 1px solid #cfcece;
  border-radius: 18px;
  cursor: pointer;
}

.popup-content {
  /* width: 100px; */

  /* width: 50%; */
}

.securitypaymentinfopopup__general {
  padding: 0;
}

.statisticspaymentpopup__info {
  display: flex;
  align-self: center;
  font-size: 20px;

  /* padding-top: 60px; */
  text-align: center;
}

.statisticspaymentpopup__buttom_elem {
  position: absolute;
  right: 20px;
  bottom: 10px;
}

.statisticspaymentpopup__buttom_icon {
  width: 130px;
  height: 130px;
  opacity: 0.7;
}

.payment-list {
  max-height: 200px;
  margin: 20px 0;
  margin-bottom: 150px;
  padding: 0;
  overflow-y: auto;
  list-style-type: none;
  background-color: #f9f9f9;
  border: 1px solid #cccccc;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
}

.payment-list__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  color: #333333;
  font-size: 16px;
  border-bottom: 1px solid #eeeeee;
}

.payment-list__item:last-child {
  border-bottom: none;
}

.payment-list__amount {
  color: #2a9d8f;
  font-weight: bold;
}

.payment-list__date {
  color: #555555;
  font-size: 14px;
}

.payment-list__item:hover {
  background-color: #e9ecef;
  cursor: pointer;
}

@media (width <= 450px) {
  .security__popup > .close {
    right: 5px;
  }

  .statisticspaymentpopup__info {
    font-size: 18px;
  }

  .statisticspaymentpopup__buttom_icon {
    width: 100px;
    height: 100px;
  }
}

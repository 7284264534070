p {
  margin: 0;
}

.resetpassword-change-popup {
  width: 100%;
  max-width: 300px;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
}

.resetpassword-change-popup__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.popup-overlay {
  backdrop-filter: blur(10px);
}

.resetpassword-change-popup__title {
  align-content: center;
  color: #5b3718;
  font-size: 24px;
}

.resetpassword-change-popup_icon {
  color: green;
  font-size: 30px;
}

.resetpassword-change-popup__close {
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
}

.resetpassword-change-popup__form {
  display: flex;
  flex-direction: column;
}

.resetpassword-change-popup__label {
  margin-bottom: 10px;
  font-weight: 500;
  text-align: center;
}

.resetpassword-change-popup__input {
  /* width: 100%; */
  min-width: 269px;
  margin-top: 5px;
  padding: 8px;
  border: 1px solid #cccccc;
  border-radius: 12px;
}

.resetpassword-change-popup__input:focus {
  border-color: rgb(168 214 99);
  outline: green;
}

.resetpassword-change-popup__input::placeholder {
  outline: green;
}

.resetpassword-change-popup__submit {
  max-width: 200px;
  min-height: 50px;
  margin: auto;
  background-color: rgb(168 214 99);
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

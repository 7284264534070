p {
  padding: 0;
}

.sidebar__general {
  opacity: 0.7;
}

.sidebar__toggle_button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  color: #ffffff;
  font-size: 16px;
  background-color: #282c34;
  background-image: url('../../../../assets/buttons/toggle_button.jpeg');
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-button:hover {
  background-color: #343a40;

  .toggle-button:active {
    background-color: #212529;
    transform: translateY(1px);
  }

  .toggle-button-icon {
    margin-right: 10px;
  }

  .css-lvmqy6 {
    border-radius: 20px;
  }
}

.personaldatatable__general {
  /* flex-grow: 1; */
  padding-top: 30px;
  padding-right: 20px;
  padding-left: 20px;
}

.personaldatatable__data {
  opacity: 0.8;
}

/* .personaldatatable__general_align {
  flex-grow: 1;
} */

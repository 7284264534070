.newyearadmission__general {
  display: flex;
  flex-direction: column;
}

.newyearadmission__logo {
  align-self: center;
  width: 300px;
  height: 156px;
  margin-top: 50px;
  vertical-align: baseline;
  background-image: url('../../../../public/logo_small.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.newyearadmission__about_chess {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  margin-right: 60px;
  margin-left: 60px;
}

.newyearadmission__general_text {
  padding-left: 100px;
}

.newyearadmission__title {
  color: white;
  font-size: 52px;
}

.newyearadmission__comercial_text {
  margin-top: 50px;
  margin-bottom: 50px;
  color: white;
  font-size: 18px;
}

.newyearadmission__main_content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 60px;
}

.newyearadmission__yellow {
  z-index: 99;
  height: 550px;
  background-color: rgb(242 185 11);
}

.newyearadmission__admission_info {
  width: 366px;
  padding: 60px;
}

.newyearadmission__button {
  color: #9a452d;
  font-size: 20px;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
}

.newyearadmission__button:hover {
  color: green;
}

.newyearadmission__comercial_girl {
  z-index: 0;
  min-width: 540px;
  height: 550px;
  margin-top: -50px;
  margin-left: -50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  object-fit: cover;
  object-position: top;
}

@media (width <= 985px) {
  .newyearadmission__about_chess {
    flex-direction: column;
  }

  .newyearadmission__general_text {
    max-width: 600px;
    margin-bottom: 40px;
    margin-left: 20px;
    padding-left: 0;
  }

  .newyearadmission__main_content {
    flex-direction: column;
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
    padding: 10px;
  }

  .newyearadmission__comercial_girl {
    max-width: 600px;
    margin-top: 40px;
    margin-right: auto;
    margin-left: auto;
  }

  .newyearadmission__admission_info {
    margin-top: 40px;
    margin-right: auto;
    margin-left: auto;
    padding: 0;
  }
}

@media (width <= 550px) {
  .newyearadmission__main_content {
    max-width: 400px;
  }

  .newyearadmission__comercial_girl {
    min-width: 365px;
    height: 380px;
  }

  .newyearadmission__title {
    font-size: 30px;
    text-align: center;
  }

  .newyearadmission__comercial_text {
    margin-left: 10px;
  }

  .newyearadmission__button {
    margin-right: auto;
    margin-left: 30px;
  }

  .newyearadmission__yellow {
    height: 400px;
  }
}

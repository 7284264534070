.externalpayment__popup {
  position: fixed;
  inset: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0 0 0 / 50%);
}

.externalpayment__popup_content {
  position: relative;
  width: 90%;
  max-width: 500px;
  padding: 20px;
  text-align: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgb(0 0 0 / 20%);
}

.externalpayment__popup_title {
  margin-bottom: 15px;
  color: #333333;
  font-size: 1.5rem;
}

.externalpayment__popup_label {
  display: block;
  margin-top: 15px;
  color: #666666;
  font-size: 1rem;
  text-align: left;
}

.externalpayment__popup_select,
.externalpayment__popup_input {
  box-sizing: border-box;
  width: 100%;
  margin-top: 5px;
  padding: 10px;
  color: #333333;
  font-size: 1rem;
  border: 1px solid #dddddd;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.externalpayment__popup_label:focus,
.externalpayment__popup_input:focus {
  border-color: #4a90e2;
  outline: none;
}

.externalpayment__popup_submit {
  display: inline-block;
  width: 100%;
  margin-top: 20px;
  padding: 12px;
  color: #ffffff;
  font-size: 1rem;
  background-color: #4a90e2;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.externalpayment__popup_submit:hover {
  background-color: #357ab8;
}

.externalpayment__popup_close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #999999;
  font-size: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.externalpayment__popup_close:hover {
  color: #333333;
}

.additionalhorizontalinfoline__additional_info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 10px;
  height: 60px;
  background: #ebb40e
    linear-gradient(0deg, #feca3a 20%, #e9b209 50%, #cb9a08 80%);
}

.additionalhorizontalinfoline__menu_icon {
  width: 60px;
  height: 60px;
}

.additionalhorizontalinfoline__exit {
  margin-left: auto;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.additionalhorizontalinfoline__exit_icon {
  width: 40px;
  height: 45px;
  padding-top: 5px;
}

.additionalhorizontalinfoline__name {
  min-width: 900px;
  margin-left: 0;
  color: #543215;
  text-align: center;
}

.additionalhorizontalinfoline__toggle_button {
  display: inline-flex;
  align-items: flex-end;
  margin-left: 10px;
  padding: 0;
  font-size: 40px;
  text-align: center;
  vertical-align: baseline;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
}

@media (width <= 1070px) {
  .additionalhorizontalinfoline__name {
    min-width: 600px;
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (width <= 750px) {
  .additionalhorizontalinfoline__additional_info {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
    height: 160px;
  }

  .additionalhorizontalinfoline__name {
    grid-column: span 2 / span 2;
  }

  .additionalhorizontalinfoline__toggle_button {
    grid-row-start: 2;
  }

  .additionalhorizontalinfoline__exit {
    grid-row-start: 2;
  }
}

@media (width <= 630px) {
  .additionalhorizontalinfoline__name {
    min-width: unset;
  }
}

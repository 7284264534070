.header__general {
  position: sticky;
  top: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 50px;
  margin: 0;
  padding: 0 20px;
  background: #ebb40e
    linear-gradient(to top, #feca3a 20%, #e9b209 50%, #cb9a08 80%);
}

.header__toggle {
  display: none;
  align-items: center;
  align-self: center;
  margin: 10px 0;
  color: black;
  font-size: 1.5rem;
  vertical-align: middle;
  background: none;
  border: none;
  cursor: pointer;
}

.header__list {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-self: center;
  gap: 40px;
  margin: 0;
  list-style-type: none;
}

.header__list_open {
  display: flex;
  flex-direction: column;
  gap: 0;
  width: 100%;
}

.header__item {
  font-weight: bold;
  line-height: 50px;
  text-transform: uppercase;
  cursor: pointer;
}

.header__log_in {
  color: white;
}

.header__log_in:hover {
  color: black;
}

.header__item:hover {
  color: white;
}

.header__link {
  color: black;
  text-decoration: none;
}

.header__link:hover {
  color: white;
}

.header__item_disabled {
  font-weight: bold;
  line-height: 50px;
  text-transform: uppercase;
}

.header__toggle:hover {
  color: white;
}

@media (width <= 1200px) {
  .header__general {
    align-items: center;
    align-self: center;
  }

  .header__toggle {
    display: block;
    font-weight: bold;
    font-size: 23px;
    vertical-align: middle;
  }

  .header__list {
    display: none;
    flex-direction: column;
    gap: 0;
    width: 100%;
    padding: 0;
  }

  .header__list_open {
    display: flex;
  }

  .header__item {
    width: 100%;
    padding: 10px 0;
    line-height: 30px;
    text-align: center;
  }
}

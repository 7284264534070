.why-we {
  padding: 40px 0;
  text-align: center;
  background-color: #f2f2f2;
}

.why-we__title {
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 40px;
}

.why-we__subtitle {
  margin-bottom: 2rem;
  color: #555555;
  font-size: 1.2rem;
}

.why-we__cards {
  margin-top: 100px;
  padding-right: 100px;
  padding-left: 100px;
}

.why-we__ulist {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 0;
  list-style-type: none;
  background-color: white;
}

.why-we__card {
  width: calc(50% - 10px);
  max-width: 300px;
  padding: 20px;
  text-align: left;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
}

.why-we__card-title {
  padding-top: 70px;
  font-size: 20px;
}

.why-we__card-text {
  padding: 20px;
  font-size: 14px;
}

.why-we__img {
  width: 100%;
  min-height: 404px;
  margin: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  object-fit: cover;
}

@media (width <= 1000px) {
  .why-we__ulist {
    grid-template-columns: 1fr 1fr;
  }

  .why-we__img {
    width: 100%;

    /* min-height: unset; */
    max-height: 300px;
  }

  .why-we__coach {
    order: -1;
  }

  .why-we__cup {
    order: 2;
  }
}

@media (width <= 750px) {
  .why-we__cards {
    padding: 15px;
  }

  .why-we__card {
    width: 100%;
  }
}

@media (width <= 700px) {
  .why-we__title {
    font-size: 30px;
  }
}

@media (width <= 500px) {
  .why-we__ulist {
    grid-template-columns: 1fr;
  }
}

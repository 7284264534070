.infotiny__cards {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: min-content;
  gap: 0;
  padding-bottom: 150px;
  background-image: url('../../../../public/intro_brick.jpg');
}

@media (width <= 1320px) {
  .infotiny__cards {
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 100px;
  }
}

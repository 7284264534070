p {
  margin: 0;
}

.teachers__cards_title {
  margin-top: 50px;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 32px;
  text-align: center;
}

.teachers__cards_subtitle {
  margin-top: 50px;
  text-align: center;
}

.teachers__cards {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  margin-top: 80px;
  margin-right: 20px;
  margin-left: 20px;
}

@media (width <= 1680px) and (width >= 1300px) {
  .teachers__cards {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (width <= 1350px) {
  .teachers__cards {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (width <= 1025px) {
  .teachers__cards {
    grid-template-columns: 1fr 1fr;
  }
}

@media (width <= 690px) {
  .teachers__cards {
    grid-template-columns: 1fr;
    margin: 90px 0 0;
  }
}

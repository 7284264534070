p {
  margin: 0;
}

h2 {
  margin: 0;
}

.teachercard__general {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.teachercard__name {
  margin-top: 20px;
  margin-bottom: 9px;
  font-size: 16px;
  text-align: center;
}

.teachercard__opapa {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.teachercard__image-container {
  position: relative;
  width: 319px;
  height: 430px;
  perspective: 1000px;
}

.teachercard__image-front,
.teachercard__image-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transition: transform 0.6s;
}

.teachercard__image-front {
  display: flex;
  justify-content: center;
  align-items: center;
}

.teachercard__image-back {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  transform: rotateY(180deg);
}

.teachercard__image-container:hover .teachercard__image-front {
  transform: rotateY(180deg);
}

.teachercard__image-container:hover .teachercard__image-back {
  transform: rotateY(0deg);
}

.teachercard__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.teachercard__position {
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 36px;
  padding: 10px 0;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  background: #ccb0a3e6;
}

.teachercard__description {
  padding: 20px;
  color: #333333;
  font-size: 14px;
  text-align: center;
}

.videosection__general {
  padding-top: 50px;
  padding-bottom: 80px;
  background-image: url('../../../../public/intro_brick.jpg');
}

.videosection__youtube {
  display: flex;
  min-width: 1122px;
  min-height: 656px;
  margin: 0 auto;
}

@media (width <= 1167px) {
  .videosection__youtube {
    min-width: 611px;
    min-height: 322px;
  }
}

@media (width <= 650px) {
  .videosection__youtube {
    min-width: 455px;
    min-height: 300px;
  }
}

@media (width <= 468px) {
  .videosection__youtube {
    min-width: 355px;
    min-height: 200px;
  }
}

@media (width <= 395px) {
  .videosection__youtube {
    min-width: 155px;
    min-height: 100px;
  }
}
